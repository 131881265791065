import { MapBounds } from './Std/MapBounds/MapBounds';

export class AppConstants {
    public static readonly coefficientEditStep: number = 0.1;
    public static readonly defaultStartHours: number = 7;
    public static readonly defaultStartMinutes: number = 0;
    public static readonly defaultEndHours: number = 21;
    public static readonly defaultEndMinutes: number = 0;
    public static readonly maxDriverExperienceCoefficient: number = 2;
    public static readonly maxProficiencyCoefficient: number = 2;
    public static readonly minDriverExperienceCoefficient: number = 0.1;
    public static readonly minProficiencyCoefficient: number = 0.1;
    public static readonly minStartHours: number = 0;
    public static readonly maxEndHours: number = 23;

    public static defaultMapBounds: MapBounds = new MapBounds([
        {
            latitude: 59.286619,
            longitude: 35.288086,
        },
        {
            latitude: 52.883477,
            longitude: 52.272949,
        },
    ]);
}
