import React, { useEffect, useState } from 'react';
import { DeliveryApi } from '../../Backend/Api/Delivery/DeliveryApi';
import { OrderCountPrediction } from '../../Model/OrderCountPrediction/OrderCountPrediction';
import styles from './OrderCountPredictionWidget.module.css';

export const OrderCountPredictionWidget = () => {
    const [prediction, setPrediction] = useState<OrderCountPrediction | null>(null);

    useEffect(() => {
        DeliveryApi.getOrderCountPrediction().then(setPrediction);
    }, []);

    if (!prediction) {
        return null;
    }

    return (
        <div className={styles.container}>
            <p>В 17:00 заказов на завтра - {prediction.initialCount}</p>
            <p>Всего на завтра ожидается</p>
            <p>заказов - {prediction.predictionOrdersCount} заказов</p>
            <p>бутылей (экв.) - {prediction.predictionShipmentCount} </p>
        </div>
    );
};
