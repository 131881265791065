import {
    ApiGeocode,
    ApiItinerary,
    ApprovePlanResponse,
    BuildTripComparisonRouteResponse,
    DeleteDriverPlaceResponse,
    DeletePlanResponse,
    DeliveriesApiDayItinerary,
    DeliveryApiAdjustIntervals,
    DeliveryApiBasketsResponse,
    DeliveryApiDriverCoefficientsOffersResponse,
    DeliveryApiDriverNoticeMailsResponse,
    DeliveryApiDriverPlaces,
    DeliveryApiDriverPoint,
    DeliveryApiImportOrderBindingsResponse,
    DeliveryApiIntervals,
    DeliveryApiOrderCountPredictionResponse,
    DeliveryApiOrderSearchDetailsResponse,
    DeliveryApiPatchTripOrdersResponse,
    DeliveryApiPlanPredictionsResponse,
    DeliveryApiPredictionResponse,
    DeliveryApiTripComparison,
    DeliveryApiTripComparisonList,
    DeliveryApiTripsOnDate,
    DeliveryPlanAnalysis,
    DeliveryPlanResponse,
    DeliveryPlanStatsResponse,
    DeliveryTripAnalysis,
    EditOrderResponse,
    GetDeletedPlansOKResponse,
    GetPlansOKResponse,
    GetWorkingDaysOKResponse,
    PostTripComparisonResponse,
    RebuildTripComparisonRouteResponse,
    ReplacementDriverResponse,
    RescheduleOrderResponse,
    RestoreOrderResponse,
    RestorePlanResponse,
    ResumeTripResponse,
    SetNewOrderPlaceResponse,
    TransferOrderResponse,
    TransferOrdersResponse,
    UnionDriverPlacesResponse,
    UnlinkAddressFromDriverPlaceResponse,
} from '@/ApiClient/Yoso/models';
import { Address } from '@/Model/Address/Address';
import { Basket } from '@/Model/Basket/Basket';
import { Car } from '@/Model/Car/Car';
import { District } from '@/Model/District/District';
import { DraftOrder } from '@/Model/DraftOrder/DraftOrder';
import { Driver } from '@/Model/Driver/Driver';
import { DriverCoefficientOffer } from '@/Model/DriverCoefficientOffer/DriverCoefficientOffer';
import { DriverNoticeMails } from '@/Model/DriverNoticeMails/DriverNoticeMails';
import { DriverPoint } from '@/Model/DriverPoint/DriverPoint';
import { ImportOrder } from '@/Model/ImportOrder/ImportOrder';
import { Itinerary } from '@/Model/Itinerary/Itinerary';
import { NoticeMail } from '@/Model/NoticeMail/NoticeMail';
import { EditOrderDto } from '@/Model/Order/EditOrderDto';
import { Order } from '@/Model/Order/Order';
import { RescheduleOrderDto } from '@/Model/Order/RescheduleOrderDto';
import { OrderCountPrediction } from '@/Model/OrderCountPrediction/OrderCountPrediction';
import { OrderSearchDetails } from '@/Model/OrderSearchDetails/OrderSearchDetails';
import { DriverPlace } from '@/Model/Place/DriverPlace';
import { Place } from '@/Model/Place/Place';
import { Prediction } from '@/Model/Prediction/Prediction';
import { Product } from '@/Model/Product/Product';
import { TripWorkingShift } from '@/Model/Trip/TripWorkingShift';
import { ITripAnalysisData } from '@/Model/TripAnalysis/ITripAnalysisData';
import { TripBasicInfo } from '@/Model/TripComparison/TripBasicInfo';
import { TripComparison } from '@/Model/TripComparison/TripComparison';
import { TripDriverPlace } from '@/Model/TripComparison/TripDriverPlace';
import { TripPointId } from '@/Model/TripPointId/TripPointId';
import { TripPredictions } from '@/Model/TripPredictions/TripPredictions';
import { DateTime } from '@/Std/DateTime';
import { DateTimePeriod } from '@/Std/DateTimePeriod';
import { DriverRouteEditing } from '@/Store/TripComparisonStore/DriverRouteEditing';
import { Api } from '../Api';

import { dateTimeSerializer } from '../Mapper/DateTimeSerializer';
import { buildPlanResponseDeserializer } from './Methods/BuildPlan/BuildPlanResponseDeserializer';
import { driverPointSerializer } from './Methods/DriverPoint/DriverPointSerializer';
import { getBasketsResponseDeserializer } from './Methods/GetBaskets/GetBasketsResponseDeserializer';
import { getDayItineraryResponseDeserializer } from './Methods/GetDayItinerary/GetDayItineraryResponseDeserializer';
import { getDriverPlacesDeserializer } from './Methods/GetDriverPlaces/GetDriverPlacesDeserializer';
import { getDriversNoticeMailsDeserializer } from './Methods/GetDriversWithNoticeMailsDeserializer/GetDriversNoticeMailsDeserializer';
import { getImportOrdersResponseDeserializer } from './Methods/GetImportOrders/GetImportOrdersResponseDeserializer';
import { getItineraryResponseDeserializer } from './Methods/GetItinerary/GetItineraryResponseDeserializer';
import { getPlanResponseDeserializer, IGetPlanResponse } from './Methods/GetPlan/GetPlanResponseDeserializer';
import { getPlanAnalysisDeserializer, IGetPlanAnalysis } from './Methods/GetPlanAnalysis/GetPlanAnalysisDeserializer';
import { getPlanPredictionsDeserializer } from './Methods/GetPlanPredictionsDeserializer/GetPlanPredictionsDeserializer';
import { getPlansResponseDeserializer, IGetPlansResponse } from './Methods/GetPlans/GetPlansResponseDeserializer';
import {
    getPlanStatisticsResponseDeserializer,
    IGetPlanStatisticsResponse,
} from './Methods/GetPlanStatistics/GetPlanStatisticsResponseDeserializer';
import { getPredictionsDeserializer } from './Methods/GetPredictionDeserializer/GetPredictionDeserializer';
import { getTripAnalysisDeserializer } from './Methods/GetTripAnalysis/GetTripAnalysisDeserializer';
import { getTripComparisonDeserializer } from './Methods/GetTripComparison/GetTripComparisonDeserializer';
import {
    getTripComparisonsDeserializer,
    IGetTripComparisonsResponse,
} from './Methods/GetTripComparisons/GetTripComparisonsDeserializer';
import {
    getWorkingDaysResponseDeserializer,
    IGetWorkingDaysResponse,
} from './Methods/GetWorkingDays/GetWorkingDaysResponseDeserializer';
import {
    IPatchTripOrdersResponse,
    patchOrdersResponseDeserializer,
} from './Methods/PatchOrders/PatchTripOrdersResponseDeserializer';
import { addressDeserializer } from './Model/Address/AddressDeserializer';
import { dateTimePeriodSerializer } from './Model/DateTimePeriod/DateTimePeriodSerializer';
import { IDeliveryPlanResponse } from './Model/DeliveryPlanResponse/DeliveryPlanResponseDeserializer';
import { draftOrdersDataSerializer } from './Model/DraftOrdersData/DraftOrdersDataSerializer';
import { driverCoefficientOfferDeserializer } from './Model/DriverCoefficientOffer/DriverCoefficientOfferDeserializer';
import { noticeMailDeserializer } from './Model/NoticeMail/NoticeMailDeserializer';
import { orderPaidMethodSerializer } from './Model/OrderPaidMethod/OrderPaidMethodSerializer';
import { orderSearchDetailsDeserializer } from './Model/OrderSearchDetailsDeserializer/OrderSearchDetailsDeserializer';
import { placeSerializer } from './Model/Place/PlaceSerializer';
import { TimeMatrixSource } from './Model/TimeMatrixSource/TimeMatrixSource';
import { timeMatrixSourceSerializer } from './Model/TimeMatrixSource/TimeMatrixSourceSerializer';
import { tripPointIdSerializer } from './Model/TripPointId/TripPointIdSerializer';

import { tripWorkingShiftSerializer } from './Model/TripWorkingShift/TripWorkingShiftSerializer';

export class DeliveryApi {
    public static async addPlanOrders(
        planId: number,
        orders: ReadonlyArray<DraftOrder>,
        cars: ReadonlyArray<Car>,
        drivers: ReadonlyArray<Driver>,
        products: ReadonlyArray<Product>,
    ): Promise<IDeliveryPlanResponse> {
        const response = await Api.doRequest<DeliveryPlanResponse>(() =>
            Api.yosoApi.addPlanOrders(
                {
                    data: draftOrdersDataSerializer.serialize({
                        cars: cars,
                        drivers: drivers,
                        orders: orders,
                        products: products,
                        districtGroup: '',
                        districts: [],
                    }),
                },
                planId,
                Api.getRequestOptions(),
            ),
        );

        return buildPlanResponseDeserializer.deserialize(response);
    }

    public static async addPlanDriver(
        planId: number,
        driverId: number,
        workingShift: TripWorkingShift,
    ): Promise<undefined> {
        await Api.doRequest<DeliveryPlanResponse>(() =>
            Api.yosoApi.addPlanDriver(
                {
                    driverId,
                    workingShift: tripWorkingShiftSerializer.serialize(workingShift),
                },
                planId,
                Api.getRequestOptions(),
            ),
        );

        return;
    }

    public static async approvePlan(planId: number): Promise<undefined> {
        await Api.doRequest<ApprovePlanResponse>(() => Api.yosoApi.approvePlan(planId, Api.getRequestOptions()));

        return;
    }

    public static async buildPlan(
        dateTimePeriod: DateTimePeriod,
        orders: ReadonlyArray<DraftOrder>,
        cars: ReadonlyArray<Car>,
        drivers: ReadonlyArray<Driver>,
        products: ReadonlyArray<Product>,
        departPeriods: string[],
        districtGroup: string,
        uploadedAt: DateTime,
        districts?: District[],
    ): Promise<IDeliveryPlanResponse> {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const parameters: Record<string, string> = {};
        for (const pair of urlSearchParams) {
            parameters[pair[0]] = pair[1];
        }

        const response = await Api.doRequest<DeliveryPlanResponse>(() =>
            Api.yosoApi.buildPlan(
                {
                    dateTimePeriod: dateTimePeriodSerializer.serialize(dateTimePeriod),
                    algorithmSettings: JSON.stringify(parameters),
                    data: draftOrdersDataSerializer.serialize({
                        cars: cars,
                        drivers: drivers,
                        orders: orders,
                        products: products,
                        departPeriods: departPeriods,
                        districtGroup: districtGroup,
                        districts,
                    }),
                    uploadedAt: dateTimeSerializer.serialize(uploadedAt),
                },
                Api.getRequestOptions(),
            ),
        );

        return buildPlanResponseDeserializer.deserialize(response);
    }

    public static async removeOrder(order: Order): Promise<undefined> {
        await Api.doRequest<TransferOrderResponse>(() => Api.yosoApi.cancelOrder(order.id, Api.getRequestOptions()));

        return;
    }

    public static async failOrder(orderId: number, reason: string, subReason?: string): Promise<undefined> {
        await Api.doRequest<TransferOrderResponse>(() =>
            Api.yosoApi.failOrder(orderId, { reason, subReason }, Api.getRequestOptions()),
        );

        return;
    }

    public static async restoreOrder(orderId: number): Promise<undefined> {
        await Api.doRequest<RestoreOrderResponse>(() => Api.yosoApi.restoreOrder(orderId, Api.getRequestOptions()));

        return;
    }

    public static async rescheduleOrder(orderId: number, rescheduleRequest: RescheduleOrderDto): Promise<undefined> {
        await Api.doRequest<RescheduleOrderResponse>(() =>
            Api.yosoApi.rescheduleOrder(
                orderId,
                {
                    newBeginTime: rescheduleRequest.timeBegin.toISOString(),
                    newEndTime: rescheduleRequest.timeEnd.toISOString(),
                    cancellationReason: rescheduleRequest.reason,
                    comment: rescheduleRequest.subReason,
                },
                Api.getRequestOptions(),
            ),
        );

        return;
    }

    public static async deletePlan(id: number): Promise<undefined> {
        await Api.doRequest<DeletePlanResponse>(() => Api.yosoApi.deletePlan(id, Api.getRequestOptions()));

        return;
    }

    public static async restorePlan(id: number): Promise<undefined> {
        await Api.doRequest<RestorePlanResponse>(() => Api.yosoApi.restorePlan(id, Api.getRequestOptions()));

        return;
    }

    public static async getPlan(
        planId: number,
        detailedTripId?: number,
        withActualRoute?: boolean,
    ): Promise<IGetPlanResponse> {
        const response = await Api.doRequest<DeliveryPlanResponse>(() =>
            Api.yosoApi.getPlan(planId, {
                ...Api.getRequestOptions(),
                detailedTripId: detailedTripId,
                withActualRoute: withActualRoute,
            }),
        );

        return getPlanResponseDeserializer.deserialize(response);
    }

    public static async getPlanAnalysis(planId: number): Promise<IGetPlanAnalysis> {
        const response = await Api.doRequest<DeliveryPlanAnalysis>(() =>
            Api.yosoApi.getPlanAnalysis(planId, Api.getRequestOptions()),
        );

        return getPlanAnalysisDeserializer.deserialize(response);
    }

    public static async getPlans(take: number, skip?: number): Promise<IGetPlansResponse> {
        const response = await Api.doRequest<GetPlansOKResponse>(() =>
            Api.yosoApi.getPlans(take, {
                ...Api.getRequestOptions(),
                skip: skip,
            }),
        );

        return getPlansResponseDeserializer.deserialize(response);
    }

    public static async getDeletedPlans(take: number, skip?: number): Promise<IGetPlansResponse> {
        const response = await Api.doRequest<GetDeletedPlansOKResponse>(() =>
            Api.yosoApi.getDeletedPlans(take, {
                ...Api.getRequestOptions(),
                skip: skip,
            }),
        );

        return getPlansResponseDeserializer.deserialize(response);
    }

    public static async getWorkingDays(take: number, skip?: number): Promise<IGetWorkingDaysResponse> {
        const response = await Api.doRequest<GetWorkingDaysOKResponse>(() =>
            Api.yosoApi.getWorkingDays(take, {
                ...Api.getRequestOptions(),
                skip: skip,
            }),
        );

        return getWorkingDaysResponseDeserializer.deserialize(response);
    }

    public static async getWorkingDay(id: number): Promise<{ baskets: Basket[]; date: DateTime }> {
        const response = await Api.doRequest<DeliveryApiBasketsResponse>(() =>
            Api.yosoApi.getWorkingDay(id, {
                ...Api.getRequestOptions(),
            }),
        );

        return getBasketsResponseDeserializer.deserialize(response);
    }

    public static async saveWorkingDay(id: number, isShort: boolean, isRestDay: boolean): Promise<undefined> {
        await Api.doRequest<undefined>(() =>
            Api.yosoApi.saveWorkingDay(
                id,
                {
                    isShort: isShort,
                    isRestDay: isRestDay,
                },
                Api.getRequestOptions(),
            ),
        );

        return;
    }

    public static async getBasket(id: number): Promise<ImportOrder[]> {
        const response = await Api.doRequest<DeliveryApiImportOrderBindingsResponse>(() =>
            Api.yosoApi.getBasket(id, {
                ...Api.getRequestOptions(),
            }),
        );

        return getImportOrdersResponseDeserializer.deserialize(response);
    }

    public static async getPlanStatistics(planId: number): Promise<IGetPlanStatisticsResponse> {
        const response = await Api.doRequest<DeliveryPlanStatsResponse>(() =>
            Api.yosoApi.getPlanStatistics(planId, Api.getRequestOptions()),
        );

        return getPlanStatisticsResponseDeserializer.deserialize(response);
    }

    public static async getTripComparisons(take: number, skip?: number): Promise<IGetTripComparisonsResponse> {
        const response = await Api.doRequest<DeliveryApiTripComparisonList>(() =>
            Api.yosoApi.getTripComparisons(take, {
                ...Api.getRequestOptions(),
                skip: skip,
            }),
        );

        return getTripComparisonsDeserializer.deserialize(response);
    }

    public static async getTripsOnDate(date: Date): Promise<TripBasicInfo[]> {
        const response = await Api.doRequest<DeliveryApiTripsOnDate>(() =>
            Api.yosoApi.getTripsOnDate(dateTimeSerializer.serializeDate(date), Api.getRequestOptions()),
        );

        return response.trips.map((t) => new TripBasicInfo(t.id, t.name));
    }

    public static async getTripComparison(tripId: number): Promise<TripComparison> {
        const response = await Api.doRequest<DeliveryApiTripComparison>(() =>
            Api.yosoApi.getTripComparison(tripId, Api.getRequestOptions()),
        );

        return getTripComparisonDeserializer.deserialize(response);
    }

    public static async postTripComparisonRoute(tripId: number, routeEditing: DriverRouteEditing): Promise<undefined> {
        await Api.doRequest<PostTripComparisonResponse>(() =>
            Api.yosoApi.postTripComparisonRoute(
                tripId,
                {
                    startPoint: driverPointSerializer.serialize(routeEditing.startPoint!),
                    finishPoint: driverPointSerializer.serialize(routeEditing.finishPoint!),
                    startTime: dateTimeSerializer.serializeDate(routeEditing.startTime!.toDate()),
                    sequence: routeEditing.sequence.map((p) => tripPointIdSerializer.serializeFromDriverRoute(p)),
                    loadingStore: routeEditing.loadingStore?.id,
                    carCapacity: routeEditing.carCapacity,
                },
                Api.getRequestOptions(),
            ),
        );

        return;
    }

    public static async buildTripComparisonRoute(
        tripId: number,
        withDriverPlace: boolean,
        withLoadingStore: boolean,
        timeMatrixSource: TimeMatrixSource,
        firstPoint: DriverPoint | number | undefined,
    ): Promise<undefined> {
        let intermediatePoint: DeliveryApiDriverPoint | undefined;
        let firstOrderId: number | undefined;
        if (firstPoint instanceof DriverPoint) {
            intermediatePoint = driverPointSerializer.serialize(firstPoint);
        } else if (firstPoint) {
            firstOrderId = firstPoint;
        }

        await Api.doRequest<BuildTripComparisonRouteResponse>(() =>
            Api.yosoApi.buildTripComparisonRoute(
                tripId,
                {
                    withDriverPlace: withDriverPlace,
                    withLoadingStore: withLoadingStore,
                    timeMatrixSource: timeMatrixSourceSerializer.serialize(timeMatrixSource),
                    intermediatePoint: intermediatePoint,
                    firstOrderId: firstOrderId,
                },
                Api.getRequestOptions(),
            ),
        );

        return;
    }

    public static async postAdjustmentsTripComparisonRoute(
        routeId: number,
        comment: string | undefined,
        distanceAdjustment: number | undefined,
        drivingTimeAdjustment: number | undefined,
    ): Promise<undefined> {
        await Api.doRequest<BuildTripComparisonRouteResponse>(() =>
            Api.yosoApi.postAdjustmentsTripComparisonRoute(
                routeId,
                {
                    comment,
                    distanceAdjustment,
                    drivingTimeAdjustment,
                },
                Api.getRequestOptions(),
            ),
        );

        return;
    }

    public static async rebuildTripComparisonRoute(routeId: number): Promise<undefined> {
        await Api.doRequest<RebuildTripComparisonRouteResponse>(() =>
            Api.yosoApi.rebuildTripComparisonRoute(routeId, Api.getRequestOptions()),
        );

        return;
    }

    public static async saveAnalyzedDriverPlaces(
        tripId: number,
        data: { orderId: number; driverPlace: TripDriverPlace }[],
    ): Promise<undefined> {
        await Api.doRequest<PostTripComparisonResponse>(() =>
            Api.yosoApi.postTripComparison(
                tripId,
                {
                    coordinates: data.map((i) => ({
                        orderId: i.orderId,
                        latitude: i.driverPlace.coords.latitude,
                        longitude: i.driverPlace.coords.longitude,
                        driverPlaceId: i.driverPlace?.id,
                    })),
                },
                Api.getRequestOptions(),
            ),
        );

        return;
    }

    public static async getTripAnalysis(tripId: number): Promise<ITripAnalysisData> {
        const response = await Api.doRequest<DeliveryTripAnalysis>(() =>
            Api.yosoApi.getTripAnalysis(tripId, Api.getRequestOptions()),
        );

        return getTripAnalysisDeserializer.deserialize(response);
    }

    public static async patchTripOrders(tripId: number, orderIds: TripPointId[]): Promise<IPatchTripOrdersResponse> {
        const response = await Api.doRequest<DeliveryApiPatchTripOrdersResponse>(() =>
            Api.yosoApi.patchTripOrders(
                tripId,
                { sequence: orderIds.map((obj) => tripPointIdSerializer.serialize(obj)) },
                Api.getRequestOptions(),
            ),
        );

        return patchOrdersResponseDeserializer.deserialize(response);
    }

    public static async setNewOrderPlace(orderId: number, place: Place): Promise<undefined> {
        await Api.doRequest<SetNewOrderPlaceResponse>(() =>
            Api.yosoApi.setNewOrderPlace(orderId, placeSerializer.serialize(place), Api.getRequestOptions()),
        );

        return;
    }

    public static async transferOrder(orderId: number, tripId: number): Promise<undefined> {
        await Api.doRequest<TransferOrderResponse>(() =>
            Api.yosoApi.transferOrder(orderId, tripId, Api.getRequestOptions()),
        );

        return;
    }

    public static async transferOrders(orderIds: number[], tripId: number): Promise<undefined> {
        await Api.doRequest<TransferOrdersResponse>(() =>
            Api.yosoApi.transferOrders(
                {
                    orderIds: orderIds,
                    toTripId: tripId,
                },
                Api.getRequestOptions(),
            ),
        );

        return;
    }

    public static async getItinerary(tripId: number): Promise<Itinerary> {
        const response = await Api.doRequest<ApiItinerary>(() =>
            Api.yosoApi.getItinerary(tripId, Api.getRequestOptions()),
        );

        return getItineraryResponseDeserializer.deserialize(response);
    }

    public static async resumeTrip(tripId: number): Promise<undefined> {
        await Api.doRequest<ResumeTripResponse>(() => Api.yosoApi.resumeTrip(tripId, Api.getRequestOptions()));

        return;
    }

    public static async getDayItinerary(date: DateTime, driverIds: number[]): Promise<Itinerary[]> {
        const response = await Api.doRequest<DeliveriesApiDayItinerary>(() =>
            Api.yosoApi.getDayItinerary(
                {
                    dateTime: dateTimeSerializer.serialize(date),
                    driverIds: driverIds,
                },
                Api.getRequestOptions(),
            ),
        );

        return getDayItineraryResponseDeserializer.deserialize(response);
    }

    public static async replaceDriverInTrip(tripId: number, driverId: number): Promise<undefined> {
        await Api.doRequest<ReplacementDriverResponse>(() =>
            Api.yosoApi.replacementDriver({ tripId: tripId, driverId: driverId }, Api.getRequestOptions()),
        );

        return;
    }

    public static async suggestAddress(search: string): Promise<Address[]> {
        const addresses = await Api.doRequest<ApiGeocode[]>(() =>
            Api.yosoApi.suggestAddress(search, Api.getRequestOptions()),
        );

        const cleanAddresses = addresses.filter((a) => a.address && a.latitude && a.longitude);

        return cleanAddresses.map((a) => addressDeserializer.deserialize(a));
    }

    public static async editOrder(orderId: number, dto: EditOrderDto): Promise<undefined> {
        await Api.doRequest<EditOrderResponse>(() =>
            Api.yosoApi.editOrder(
                orderId,
                {
                    paidMethod: dto.paidMethod ? orderPaidMethodSerializer.serialize(dto.paidMethod) : undefined,
                },
                Api.getRequestOptions(),
            ),
        );

        return;
    }

    public static async getDriverPlaces(driverId: number): Promise<DriverPlace[]> {
        const response = await Api.doRequest<DeliveryApiDriverPlaces>(() =>
            Api.yosoApi.getDriverPlaces(driverId, Api.getRequestOptions()),
        );

        return getDriverPlacesDeserializer.deserialize(response);
    }

    public static async deleteDriverPlace(id: number): Promise<void> {
        await Api.doRequest<DeleteDriverPlaceResponse>(() =>
            Api.yosoApi.deleteDriverPlace(id, Api.getRequestOptions()),
        );
    }

    public static async unionDriverPlace(idToDelete: number, idToUnion: number): Promise<void> {
        await Api.doRequest<UnionDriverPlacesResponse>(() =>
            Api.yosoApi.unionDriverPlaces({ idToDelete, idToUnion }, Api.getRequestOptions()),
        );
    }

    public static async getPlanPredictions(planId: number): Promise<TripPredictions[]> {
        const response = await Api.doRequest<DeliveryApiPlanPredictionsResponse>(() =>
            Api.yosoApi.getPlanPredictions(planId, Api.getRequestOptions()),
        );

        return getPlanPredictionsDeserializer.deserialize(response);
    }

    public static async getPrediction(predictionId: number): Promise<Prediction> {
        const response = await Api.doRequest<DeliveryApiPredictionResponse>(() =>
            Api.yosoApi.getPrediction(predictionId, Api.getRequestOptions()),
        );

        return getPredictionsDeserializer.deserialize(response);
    }

    public static async unlinkAddressFromDriverPlace(addressId: string, driverPlaceId: number): Promise<void> {
        await Api.doRequest<UnlinkAddressFromDriverPlaceResponse>(() =>
            Api.yosoApi.unlinkAddressFromDriverPlace(addressId, driverPlaceId, Api.getRequestOptions()),
        );
    }

    public static async getDriversWithNoticeMails(): Promise<DriverNoticeMails[]> {
        const response = await Api.doRequest<DeliveryApiDriverNoticeMailsResponse>(() =>
            Api.yosoApi.getDriversWithNoticeMails(Api.getRequestOptions()),
        );

        return getDriversNoticeMailsDeserializer.deserialize(response);
    }

    public static async getNoticeMailsFromDate(date: DateTime): Promise<DriverNoticeMails[]> {
        const response = await Api.doRequest<DeliveryApiDriverNoticeMailsResponse>(() =>
            Api.yosoApi.getNoticeMailsFromDate(dateTimeSerializer.serialize(date), Api.getRequestOptions()),
        );

        return getDriversNoticeMailsDeserializer.deserialize(response);
    }

    public static async getArchiveDriverNoticeMails(id: number, date: DateTime, take: number): Promise<NoticeMail[]> {
        const response = await Api.doRequest<DeliveryApiDriverNoticeMailsResponse>(() =>
            Api.yosoApi.getArchiveDriverNoticeMails(
                id,
                dateTimeSerializer.serialize(date),
                take,
                Api.getRequestOptions(),
            ),
        );

        return response.mails.map((i) => noticeMailDeserializer.deserialize(i));
    }

    public static async getDriverCoefficientsOffers(): Promise<DriverCoefficientOffer[]> {
        const response = await Api.doRequest<DeliveryApiDriverCoefficientsOffersResponse>(() =>
            Api.yosoApi.getDriverCoefficientsOffers(Api.getRequestOptions()),
        );

        return response.offers.map((i) => driverCoefficientOfferDeserializer.deserialize(i));
    }

    public static async getOrderCountPrediction(): Promise<OrderCountPrediction | null> {
        const response = await Api.doRequest<DeliveryApiOrderCountPredictionResponse>(() =>
            Api.yosoApi.getOrderCountPrediction(Api.getRequestOptions()),
        );

        if (response.prediction) {
            const { initialCount, predictionOrdersCount, predictionShipmentCount } = response.prediction;

            return { initialCount, predictionOrdersCount, predictionShipmentCount };
        }

        return null;
    }

    public static async searchOrders(planId: number, searchText: string): Promise<OrderSearchDetails[]> {
        const response = await Api.doRequest<DeliveryApiOrderSearchDetailsResponse>(() =>
            Api.yosoApi.searchOrders(planId, searchText, Api.getRequestOptions()),
        );

        return response.orders.map((i) => orderSearchDetailsDeserializer.deserialize(i));
    }

    public static async getIntervals(): Promise<DeliveryApiIntervals> {
        const response = await Api.doRequest<DeliveryApiIntervals>(() =>
            Api.yosoApi.getDeliveryIntervals(Api.getRequestOptions()),
        );

        return response;
    }

    public static async adjustIntervals(request: DeliveryApiAdjustIntervals): Promise<undefined> {
        await Api.doRequest<DeliveryApiAdjustIntervals>(() =>
            Api.yosoApi.adjustDeliveryIntervals(request, Api.getRequestOptions()),
        );

        return;
    }
}
